<template>
  <div class="p-1">
    <div class="row">
      <div class="col">
        <select2 id="s2_1" class="fontstd" v-model="search_type_select" :options="search_type" :settings="{ width: '100%' }"></select2>
      </div>
    </div>
    <div class="row">
      <div class="col p-1 ml-2">
        <input id="inp_u1" class="w-100 m-1 p-1 fontstd" @change="onChange($event)" :value="treeFilter" @keyup.enter="onFilterEnter">
      </div>
      <div class="col-1 cpoint ml-2 mr-0 pt-2" @click="onFilter">
        <span class="fas fa-search"></span>
      </div>
      <div class="col-1 cpoint pt-2 mr-2" @click="clearFilter">
        <span class="fas fa-times"></span>
      </div>
    </div>
    <div class="row">
      <div class="col doptext">
        <input id="inp_u2" v-model="showDelBill" class="m-1" type="checkbox"> {{ $t('tree.billtree.showDeletedAccounts') }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span class="doptext">{{ $t('tree.billtree.selectAccounts') }}</span><span class="label selected">{{cntAlBills}}</span> <span class="label label-info">{{cntSelBill}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col p-1">
        <button @click="selectAll" class="fontstd_bt btn btn-info px-1 py-0 m-1">{{ $t('tree.billtree.selectAll') }}</button>
        <button @click="resetAll" class="fontstd_bt btn btn-secondary px-1 py-0 m-1">{{ $t('tree.billtree.resetSelection') }}</button>
      </div>
    </div>
    <div>
      <div class="sidebar">
        <Branches v-show="is_tree_search" :cur_lv="mainBill" :cur_lv_of_top="1"></Branches>
      </div>
    </div>
    <div v-show="!is_tree_search" class="alert alert-primary m-2">{{ $t('tree.billtree.noAccountsFound') }}</div>
  </div>
</template>

<script setup>
import Branches from "./Branches.vue";
import BillService from "../../services/bill.service";
import {provide, ref, inject, computed, watch, onMounted, onActivated, defineEmits, watchEffect} from 'vue'
import {useStore} from "vuex";

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const emit = defineEmits(['select', 'onSelect'])

const store = useStore()

const selBillsInfoAll = inject('selBillsInfoAll')

const setIsLoading = inject('setIsLoading')

const set_result = inject('set_result')

const showDelBill = ref(false)

const filterUse = ref(false)

const cntSelBill = inject('cntSelBill')
const cntAlBills = ref(0)

const getBillsInfo = inject('getBillsInfo')
const getBillsInfoAll = inject('getBillsInfoAll')

const cnttable = inject('cnttable')
const cntintegration = inject('cntintegration')

const search_type = computed(() => [
  {"id": "billnameid", "text": $t('tree.billtree.accountNumberAndName')},
  {"id": "cashier_user_login", "text": $t('tree.billtree.userLogin')},
  {"id": "billcomment", "text": $t('tree.billtree.accountComment')}
])
const search_type_select = ref("billnameid")

const is_tree_search = ref(true)

const treeData = inject('treeData')
const setBillsTreeData = inject('setBillsTreeData')

const getTreeBills = inject('getTreeBills')

const treeFilter = ref('')

const is_filter = computed(() => treeFilter.value!=undefined && treeFilter.value!='')
provide('is_filter', is_filter)

const lv_pad=ref(0)
provide('lv_pad', lv_pad)

const curlv=ref(0)

const calc_data_type = inject('calc_data_type')

function onChange(event)
{
  treeFilter.value=event.target.value
}

function recursiveParent (login)
{
  curlv.value++

  for (let key in treeData.value) {
    let cur = treeData.value[key]
    let keys = Object.keys(cur)
    if(keys.indexOf(login)!=-1) {
      if(is_filter.value==true) {
          store.dispatch('bill/addCollapseBills', key)
      }

      recursiveParent(key)
    }
  }
}

const curReq = ref([])
function recursiveSub(login, checkState)
{
  let lglist=treeData.value[login]
  let selAr=[login]

  for(let key in lglist) {
    let cur = lglist[key]

    if(checkState==true) {
      store.state.bill.tblCnt=store.state.bill.tblCnt+parseInt(cur.tbl_cnt)
      store.state.bill.intCnt=store.state.bill.intCnt+parseInt(cur.int_cnt)

      calc_data_type('add', cur.login, cur.data_type)
    } else {
      store.state.bill.tblCnt=store.state.bill.tblCnt-parseInt(cur.tbl_cnt)
      store.state.bill.intCnt=store.state.bill.intCnt-parseInt(cur.int_cnt)

      calc_data_type('del', cur.login, cur.data_type)
    }

    if(store.state.bill.tblCnt<0) {
      store.state.bill.tblCnt=0
    }

    if(store.state.bill.intCnt<0) {
      store.state.bill.intCnt=0
    }

    curReq.value.push(cur.login)

    recursiveSub(key, checkState)
  }

  return selAr
}
provide('recursiveSub', recursiveSub)

function checkOne(key)
{
  calc_data_type('clear')
  store.dispatch('bill/clearSelBill', key)
  BillService.getBill(key).then(
      (response) => {
        store.dispatch('bill/selOneBillInfo', response.data)

        let sel_bill=selBillsInfoAll.value[key]

        let tbl_cnt=0
        let int_cnt=0
        if(sel_bill!=undefined) {
          tbl_cnt=parseInt(sel_bill.tbl_cnt)
          int_cnt=parseInt(sel_bill.int_cnt)
        }
        store.state.bill.tblCnt=tbl_cnt
        store.state.bill.intCnt=int_cnt

        calc_data_type('add', sel_bill.id, sel_bill.data_type)
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}
provide('checkOne', checkOne)

function check(key, checkState, recursive=true)
{
  curReq.value=[]
  curReq.value.push(key)

  if(recursive==true) {
    recursiveSub(key, checkState)
  }

  let sel_bill=selBillsInfoAll.value[key]
  let tbl_cnt=0
  let int_cnt=0
  if(sel_bill!=undefined) {
    tbl_cnt=parseInt(sel_bill.tbl_cnt)
    int_cnt=parseInt(sel_bill.int_cnt)
  }
  if(checkState) {
    store.dispatch('bill/addSelBill', curReq.value)
    store.state.bill.tblCnt=store.state.bill.tblCnt+tbl_cnt
    store.state.bill.intCnt=store.state.bill.intCnt+int_cnt

    calc_data_type('add', sel_bill.id, sel_bill.data_type)
  } else {
    store.dispatch('bill/delSelBill', curReq.value)
    store.state.bill.tblCnt=store.state.bill.tblCnt-tbl_cnt
    store.state.bill.intCnt=store.state.bill.intCnt-int_cnt

    calc_data_type('del', sel_bill.id, sel_bill.data_type)
  }

  if(cnttable.value<0) {
    store.state.bill.tblCnt=store.state.bill.tblCnt=0
  }

  if(cntintegration.value<0) {
    store.state.bill.intCnt=store.state.bill.intCnt=0
  }

  getBillsInfo()
}
provide('check', check)

function find_main_bill(obj)
{
  let fkey=null

  for(let key in obj) {
    let cur = obj[key]
    var keyss = Object.keys(cur)
    let fkeys=keyss[0]
    if(cur[fkeys].ord_lv==1 || fkey==null) {
      fkey=key
    }
  }

  return fkey
}

function selectAll()
{
  let obj=treeData.value
  let fk=find_main_bill(obj)

  let getbill = treeData.value[fk]
  let fkb=Object.keys(getbill)[0]

  if(mainBill.value=='superbill') {
    fkb="1"
  }

  store.state.bill.tblCnt=0
  store.state.bill.intCnt=0

  calc_data_type('clear')

  check(fkb, true)
}

function resetAll()
{
  calc_data_type('clear')

  store.dispatch('bill/clearSelBill', null)
}

function collapseBills_fn()
{
  return store.state.bill.collapseBills
}
const collapseBills = computed(collapseBills_fn)
provide('collapseBills', collapseBills)

function is_collapse(id)
{
  let res=false
  let is_arr = collapseBills.value.indexOf(id)
  if(is_arr>=0) {
    res=true
  }

  return res
}
provide('is_collapse', is_collapse)

function is_child_show(cur, tp='')
{
  let res=false

  let cur_childs = treeData.value[cur]
  let res_hide=false
  if(cur_childs!==undefined) {
    for (let key in cur_childs)
    {
      let cur = cur_childs[key]
      if (cur.hide!=true) {
        res_hide=true
      }
    }
  }

  if(res_hide==true) {
    res=true
  }

  return res
}
provide('is_child_show', is_child_show)

const is_child_show_first = ref([])
function collapse(cur, state_man)
{
  setFirsFind(false)
  for (let key in treeData.value[cur]) {
    treeData.value[cur][key]['hide']=true
  }

  let state=is_collapse(cur)

  if(state_man!=undefined) {
    state=state_man
  }

  if(is_filter.value==true && is_child_show(cur)==false && is_child_show_first.value.indexOf(cur)==-1) {
    is_child_show_first.value.push(cur)
    state=false
  }

  if(state) {
    store.dispatch('bill/delCollapseBills', cur)
  } else {
    store.dispatch('bill/addCollapseBills', cur)
  }
}
provide('collapse', collapse)

const firsFind = ref(false)
provide('firsFind', firsFind)

function setFirsFind(val)
{
  firsFind.value=val
}
provide('setFirsFind', setFirsFind)

const is_firs_find = ref(false)

function onFilter()
{
  is_firs_find.value=true

  setFirsFind(true)
  lv_pad.value=0

  if(is_filter.value!=true && filterUse.value==true) {
    store.dispatch('bill/clearCollapseBills')
  }

  if(is_filter.value==true) {
    filterUse.value=true
  }

  for (let key in treeData.value) {
    for (let key2 in treeData.value[key]) {
      if (search_type_select.value == "billnameid") {
        var reg_num = /^[0-9]*$/;
        let is_num = treeFilter.value.match(reg_num)

        let idLike = -1
        if(treeData.value[key][key2].login==treeFilter.value) {
          idLike=1
        }

        let nameLike = -1
        if(is_num==null || treeFilter.value=='') {
          nameLike = treeData.value[key][key2].title.toLowerCase().indexOf(treeFilter.value.toLowerCase())
        }

        if (idLike == -1 && nameLike == -1) {
          treeData.value[key][key2]['hide'] = false
        } else {
          treeData.value[key][key2]['hide'] = true

          curlv.value=0
          recursiveParent(key2)
          if(lv_pad.value==0 || lv_pad.value>curlv.value) {
            lv_pad.value=curlv.value
          }
        }
      }

      if (search_type_select.value == "cashier_user_login") {
        let login_list = treeData.value[key][key2].cashier_user_login.toLowerCase().split(' ')
        let cashier_user_login_is_find = login_list.includes(treeFilter.value.toLowerCase())

        if(treeFilter.value.toLowerCase().trim()=='') {
          cashier_user_login_is_find = true
        }

        if (cashier_user_login_is_find !== true) {
          treeData.value[key][key2]['hide'] = false
        } else {
          treeData.value[key][key2]['hide'] = true

          curlv.value=0
          recursiveParent(key2)
          if(lv_pad.value==0 || lv_pad.value>curlv.value) {
            lv_pad.value=curlv.value
          }
        }
      }

      if (search_type_select.value == "billcomment") {
        let commentLike = treeData.value[key][key2].comment.toLowerCase().indexOf(treeFilter.value.toLowerCase())
        if (commentLike == -1) {
          treeData.value[key][key2]['hide'] = false
        } else {
          treeData.value[key][key2]['hide'] = true

          curlv.value=0
          recursiveParent(key2)
          if(lv_pad.value==0 || lv_pad.value>curlv.value) {
            lv_pad.value=curlv.value
          }
        }
      }
    }
  }

  if(is_filter.value!=true) {
    lv_pad.value=0
  }

  if(collapse_bills_list.value.length>0)
  {
    for(let key in collapse_bills_list.value)
    {
      let cur = collapse_bills_list.value[key]

      collapse(cur, false)
    }

    collapse_bills_list.value = []

    checkOne(collapse_bills.value)
  }

  is_firs_find.value=false
}

function onFilterEnter()
{
  if(treeFilter.value=='') {
    clearFilter()
  } else {
    onFilter()
  }
}

function changeShowDel()
{
  getBills()
}
watch(showDelBill, changeShowDel)

function getBills()
{
  setIsLoading(true)

  BillService.getBills(showDelBill.value).then(
      response => {
        setIsLoading(false)

        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        getTreeBills(response.data)

        cntAlBills.value = 0
        for (let key in treeData.value) {
          for (let key2 in treeData.value[key]) {
            cntAlBills.value=cntAlBills.value+1

            if(treeData.value[key2]==undefined) {
              let objc = treeData.value[key][key2]

              delete treeData.value[key][key2]

              objc.pref='np'
              treeData.value[key][objc.pref+key2]=objc
            }
          }
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function clearFilter()
{
  treeFilter.value = ''

  store.dispatch('bill/clearCollapseBills')

  filterUse.value=false

  for (let key in treeData.value) {
    for (let key2 in treeData.value[key]) {
      treeData.value[key][key2]['hide'] = true
    }
  }

  if(is_filter.value!=true) {
    lv_pad.value=0
  }
}

function checkAll(key, event) {
  let checkState = event.target.checked

  let recursive_flag=checkState

  if(!is_collapse(key)) {
    recursive_flag=true
  }

  check(key, checkState, recursive_flag)
}
provide('checkAll', checkAll)

function ndclass(cur)
{
  let res = ''

  if(treeData.value[cur.login]) {
    if (!is_collapse(cur.login)) {
      res = 'ndclosed'
    }

    if (is_collapse(cur.login)) {
      res = 'ndopen'
    }
  } else {
    res = 'ndleaf'
  }

  if(is_filter.value==true && is_child_show(cur.login)==true) {
    res = 'ndclosed'
  }

  return res
}
provide('ndclass', ndclass)

function mainBill_fn()
{
  let mBill='superbill';
  let obj=treeData.value
  let fkey=find_main_bill(obj)
  var keys = Object.keys(obj)

  if(keys.indexOf('superbill')==-1) {
    mBill=fkey

    //collapse first bill
    if(obj[fkey]!==undefined) {
      let first_bill=Object.keys(obj[fkey])
      store.dispatch('bill/addCollapseBills', first_bill[0])
    }
  } else {
    store.dispatch('bill/addCollapseBills', '1')
  }

  return mBill
}
const mainBill = computed(mainBill_fn)

function updBillsFn()
{
  if(store.state.bill.isUpdBills>0) {
    getBills()
  }
}
watchEffect(updBillsFn)

const collapse_bills = inject('collapse_bills')
const collapse_bills_list = inject('collapse_bills_list')

function collapse_bills_wt()
{
  treeFilter.value=''

  if(Object.entries(collapse_bills_list.value).length > 0) {
    clearFilter()

    if(collapse_bills_list.value.length>0) {
      for (let key in collapse_bills_list.value) {
        let cur = collapse_bills_list.value[key]

        collapse(cur, false)
      }

      collapse_bills_list.value = []

      checkOne(collapse_bills.value)

      collapse_bills.value = 0
      collapse_bills_list.value = {}
    }
  }
}
watchEffect(collapse_bills_wt)

onMounted(() => {
  getBills()
  getBillsInfoAll()
})
</script>

<style>
.ultree li, .ultree li label, .ultree li label input  {
  cursor: pointer;
}

.cpoint {
  cursor: pointer;
}

.ullevel{
  padding-left: 12px;
}

.ullevel0{
  padding-left: 0px;
}

.item {
  padding: 0 5px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 9pt;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
}

#sidebar-body ul {
  font-size: 75%;
}

ul.unstyled {
  margin-left: 0;
  list-style: none;
}

.itemdiv {
  white-space: nowrap
}

li.lielem {
  padding: 6px 0;
  position: relative;
}

li.lielem::before {
  content: '';
  position: absolute;
  right: auto;
  left: -11px;
  border-left: 1px solid #999;
  bottom: 50px;
  height: 100%;
  top: -8px;
  width: 1px;
}

.tree-expand.ndopen {
  background-image: url(data:image/gif;base64,R0lGODlhEgASAMZFAAAAAHmWwURERDk5OYGcxfz8/Pr6+uLm7P///v7+/e7w84GdxfP09u/x9PX29+ns8Pz8+/79/crT4Pn6+uXo7ufq77rG183V4fDy9MXP3ezu8vHz9e30/tbc5vf3+Ovu8fb3+Obq7+zv8sTO3Ojr79vg6O3v8uPn7cTN3P7+/+Xp7n2axN3i6vj5+YCcxd7j6tzh6ff4+PLz9YGcxPHy9NTb5d/k6/v7+/n5+dLZ5P3+/77J2dXb5b/J2evt8czU4dPa5PT19uTo7cvU4P7+/v////n5+vT199rf6Ort8f///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////yH5BAEAAH8ALAAAAAASABIAAAeFgH+Cg4SFhoeIiYqLiAGOj5ABhwEWPRkXHTYqPg0zkzsjPzwvFEkNQQSTKEM1LEIPCgwxqYYBEkAwJ7AMHga0hQE5JQPEAwIGBQsCy8uCAUgHJCYyIBMFRAuTBxUiGw5GBQlFv4QBIRo0DjgQCQg6LpMfGEctNxEIKRwrk5GRjP8AAxYKBAA7);
  cursor: pointer;
}

.tree-expand.ndclosed {
  background-image: url(data:image/gif;base64,R0lGODlhEgASAMZDAAAAAHmWwURERDk5OYGcxfz8/Pr6+v///uLm7P7+/YGdxfP09u/x9O7w88rT4P79/fHz9brG1/n6+vz8++fq7/Dy9MXP3czU4c3V4e30/tbc5uzu8uXo7vf3+Ovu8fb3+Obq7+zv8sTO3Ojr79vg6O3v8uPn7cTN3P7+/+Xp7n2axICcxYGcxPn5+fj5+ff4+N7j6t3i6tLZ5N/k6/X29+ns8NTb5f3+//v7+/Hy9NXb5b/J2fT1977J2dPa5PT19uvt8drf6P7+/v///9zh6cvU4Ort8fn5+v///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////yH5BAEAAH8ALAAAAAASABIAAAeFgH+Cg4SFhoeIiYqLiAGOj5ABhwEROxYYGjMpQAwskz0iFzowHEYMPwSTJ0U2MQM1DQsvqYYBDj5EJgOxHQa0hQEyJAPEAwIGBQoCy8uCAUEIIyUDHxIFQgqTCBQhEAJHBQlDv4QBIBs5NC0TCQc3K5MeFTwuOA8HKBkqk5GRjP8AAxYKBAA7);
  cursor: pointer;
}

.tree-expand.ndleaf {
  background-image: url(data:image/gif;base64,R0lGODlhEgASAKECAAAAAHJycv///////yH5BAEAAAAALAAAAAASABIAAAIVhI+py+0Po5xUhjuv3lr1CobiSFYFADs=);
}

.tree-expand {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.lielem input[type=checkbox] {
  margin: 0;
}
</style>

<style scoped>
.sidebar {
  height: 83vh;
  overflow-y: auto;
}

.cpoint  {
  cursor: pointer;
}

.doptext {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 10pt;
  color: #333;
}

.fontstd {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 9pt;
  color: #333;
}

.fontstd_bt {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 9pt;
}

.label {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  border-radius: 3px;
}

.selected {
  background-color: #468847;
}

.label-info {
    background-color: #3a87ad;
}
</style>
