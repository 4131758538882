import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'bill/';

const config = {
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  },
  withCredentials: true,
  crossOrigin: true
}

class BillService {
  getBills(showDelBill) {
    return axios.post(API_URLA + 'getBills/', {"showDelBill": showDelBill}, config);
  }
  getBillsAll(showBlockBill, showDelBill) {
    return axios.post(API_URLA + 'getBillsAll/', {"showBlockBill": showBlockBill, "showDelBill": showDelBill}, config);
  }
  getBillsParentSel(bill_id) {
    return axios.post(API_URLA + 'getBillsParentSel/', {"bill_id": bill_id}, config);
  }
  getMainBill() {
    return axios.post(API_URLA + 'getMainBill/', {}, config);
  }
  changeBalanse(source_bill, destination_bill, sum, type_transactions, user_comment, save_balance) {
    return axios.post(API_URLA + 'changeBalanse/', {"source_bill": source_bill, "destination_bill": destination_bill, "sum": sum, "type_transactions": type_transactions, "user_comment": user_comment, "save_balance": save_balance}, config);
  }
  getSettings(bill_id) {
    return axios.post(API_URLA + 'getSettings/', {"bill_id": bill_id}, config);
  }
  saveSettings(bill_id, settings, show_nearest_event, preview_auto_payout) {
    return axios.post(API_URLA + 'saveSettings/', {"bill_id": bill_id, "settings": settings, "show_nearest_event": show_nearest_event, "preview_auto_payout": preview_auto_payout});
  }
  getBill(bill_id) {
    return axios.post(API_URLA + 'getBill/', {"bill_id": bill_id}, config);
  }
  saveBill(data) {
    return axios.post(API_URLA + 'saveBill/', data, config);
  }
  getSettByCurency(currency_id) {
    return axios.post(API_URLA + 'getSettByCurency/', {"currency_id": currency_id}, config);
  }
  setActiveStatus(id, status) {
    return axios.post(API_URLA + 'setActiveStatus/', {"id": id, "status": status}, config);
  }
  getHistory(bill_id) {
    return axios.post(API_URLA + 'getHistory/', {"bill_id": bill_id}, config);
  }
  getStatistic(bill_id) {
    return axios.post(API_URLA + 'getStatistic/', {"bill_id": bill_id}, config);
  }
  clearStatistic(bill_id, game_id, game_nameid) {
    return axios.post(API_URLA + 'clearStatistic/', {"bill_id": bill_id, "game_id": game_id}, config);
  }
  verifyBlock(id) {
    return axios.post(API_URLA + 'verifyBlock/', {"id": id}, config);
  }
  getBillsForBlocked(id) {
    return axios.post(API_URLA + 'getBillsForBlocked/', {"id": id}, config);
  }
  getBillsByList(bills_id) {
    return axios.post(API_URLA + 'getBillsByList/', {"bills_id": bills_id}, config);
  }
  getBillsBetsKoef() {
    return axios.post(API_URLA + 'getBillsBetsKoef/', {}, config);
  }
  saveBillBetsKoef(data) {
    return axios.post(API_URLA + 'saveBillBetsKoef/', data, config);
  }
}

export default new BillService();
