import axios from 'axios';
import md5 from 'js-md5'

const API_URL = process.env.VUE_APP_APIA+'auth/';

const config = {
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  },
  withCredentials: true,
  crossOrigin: true
}

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin/?login='+user.username, {
        login: user.username,
        password: md5(user.password)
      }, config)
      .then(response => {
        if (response.data.accessToken) {
          let udata = response.data;
          udata['secret'] = md5(user.password);

          sessionStorage.setItem('user', JSON.stringify(udata));
        } else {
          sessionStorage.setItem('user', '{}');
        }

        return response.data;
      });
  }

  logout() {
    sessionStorage.removeItem('user');

    return axios.post(API_URL + 'signout/');
  }
}

export default new AuthService();
