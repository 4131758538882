import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'currencie/';

const config = {
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  },
  withCredentials: true,
  crossOrigin: true
}

class CurrencyService {
  getCurrenciesList() {
    return axios.post(API_URLA + 'getCurrenciesList/', {}, config);
  }
  getCurrencies() {
    return axios.post(API_URLA + 'getCurrencies/', {}, config);
  }
  getCurrency(iso) {
    return axios.post(API_URLA + 'getCurrency/', {"iso": iso}, config);
  }
  getExchangeRates(iso) {
    return axios.post(API_URLA + 'getExchangeRates/', {"iso": iso}, config);
  }
  saveCurrency(data) {
    return axios.post(API_URLA + 'saveCurrency/', data, config);
  }
}

export default new CurrencyService();
